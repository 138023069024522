import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import SegmentCard from '../elements/SegmentCard';
import * as config from '../../../config/site';

function ProductSegments({
  segmenttype,
  segments = [],
  showHeader,
  showCardHeader,
  catalogType,
  format,
}) {
  const segmentLabel = config.catalog[segmenttype].label;
  const hasItems = segments && segments.length > 0;

  return (
    <Fragment>
      {hasItems && (
        <StyledBox component="section" pt={{ xs: 3, md: 6 }}>
          <Container>
            {showHeader && (
              <StyledBox ml={1} mb={4}>
                <StyledTypography variant="h3">{segmentLabel}</StyledTypography>
              </StyledBox>
            )}
            <Grid container spacing={3}>
              {segments.map((segment) => (
                <Fragment>
                  {format === 'standard' && (
                    <Grid key={segment.node.id} item xs={12} md={6} lg={4}>
                      <StyledBox>
                        <SegmentCard
                          segmenttype={segmenttype}
                          showHeader={showCardHeader}
                          segment={segment}
                          catalogType={catalogType}
                        />
                      </StyledBox>
                    </Grid>
                  )}
                  {format === 'large' && (
                    <Grid key={segment.node.id} item xs={12} md={6}>
                      <StyledBox>
                        <SegmentCard
                          segmenttype={segmenttype}
                          showHeader={showCardHeader}
                          segment={segment}
                          catalogType={catalogType}
                        />
                      </StyledBox>
                    </Grid>
                  )}
                </Fragment>
              ))}
            </Grid>
          </Container>
        </StyledBox>
      )}
    </Fragment>
  );
}

ProductSegments.defaultProps = {
  showHeader: false,
  showCardHeader: true,
  format: 'standard',
};

ProductSegments.propTypes = {
  segmenttype: PropTypes.string.isRequired,
  segments: PropTypes.array,
  showHeader: PropTypes.bool,
  showCardHeader: PropTypes.bool,
  catalogType: PropTypes.string.isRequired,
  format: PropTypes.oneOf(['standard', 'large']),
};

export default ProductSegments;
