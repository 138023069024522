import React, { Fragment } from 'react';
import { graphql, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import _ from 'lodash';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Card from '@mui/material/Card';

// custom components
import { useSettings } from '../../hooks/SettingsHook';
import { useAuth } from '../../hooks/AuthHook';
import { Layout } from '../../components/layout/CatalogDefault';
import StyledBox from '../../components/controls/StyledBox';
import Locked from '../../custom/segments/LockedBlocked';
import Content from '../../custom/segments/Content';
import ProductSegments from '../../custom/segments/ProductSegments';
import Gallery from '../../custom/segments/Gallery';
import Downloads from '../../custom/segments/Downloads';
import Newsletter from '../../custom/segments/NewsletterSimple';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

export const query = graphql`
  query ($id: String, $catalog: String) {
    assortment: datoCmsAssortment(id: { eq: $id }) {
      title
      teaser
      content
      footer
      protected
      noCatalog
      catalogs {
        id
        title
      }
      logo {
        gatsbyImageData(width: 150)
        alt
      }
      coverImage {
        gatsbyImageData(width: 1600)
        alt
      }
      gallery {
        alt
        basename
        fixed(width: 250) {
          src
        }
        gatsbyImageData(layout: CONSTRAINED, width: 1200, aspectRatio: 1.3)
      }
      downloads {
        title
        basename
        url
        customData
        size
        format
        filename
        alt
      }
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    topics: allDatoCmsTopic(
      filter: {
        catalogs: { elemMatch: { id: { eq: $catalog } } }
        assortments: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          ...TopicWithImageCard
        }
      }
    }
    destinations: allDatoCmsDestination(
      filter: {
        catalogs: { elemMatch: { id: { eq: $catalog } } }
        assortments: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          ...DestinationWithImageCard
        }
      }
    }
    triptypes: allDatoCmsTriptype(
      filter: {
        catalogs: { elemMatch: { id: { eq: $catalog } } }
        assortments: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          ...TriptypeWithImageCard
        }
      }
    }
    venues: allDatoCmsVenue(
      filter: {
        catalogs: { elemMatch: { id: { eq: $catalog } } }
        assortments: { elemMatch: { id: { eq: $id } } }
      }
      sort: { fields: position, order: ASC }
    ) {
      edges {
        node {
          ...VenueWithImageCard
        }
      }
    }
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
  }
`;

export default function AssortmentPage({ data, pageContext }) {
  // context
  const location = useLocation();
  const { saveSettings } = useSettings() || {};
  const { user, isValid } = useAuth() || {};
  // parameter management
  const { assortment, site } = data;
  const { topics, destinations, venues, triptypes } = data;
  const { catalogType, catalog } = pageContext;
  // state

  // event handler
  const handleLockedLoginClick = () => {
    const backlink = location.pathname;
    saveSettings({ caller: { login: backlink } });
    navigate(config.pages.login.path);
  };

  // data management
  const isProtected = assortment && assortment.protected ? true : false;
  const isAuthenticated = isValid ? isValid() : false;
  const isAuthorized = isProtected ? isAuthenticated : true;

  // console.log('=== ASSORTMENT ===');
  // console.log(`assortment: ${assortment.title}`);
  // console.log('User');
  // console.log(user);
  // console.log('Location');
  // console.log(location);
  // console.log(`isProtected: ${isProtected}`);
  // console.log(`isAuthenticated: ${isAuthenticated}`);
  // console.log(`isAuthorized: ${isAuthorized}`);

  const coverHeader = assortment.title;
  const allTopics = topics.edges;
  const allDestinations = destinations.edges;
  const allTriptypes = triptypes.edges;
  const allVenues = venues.edges;

  const showNewsletter = config.segments.newsletter.enabled;

  const hasTopics = !_.isEmpty(allTopics) && allTopics.length > 0;
  const hasDestinations = !_.isEmpty(allDestinations) && allDestinations.length > 0;
  const hasTriptypes = !_.isEmpty(allTriptypes) && allTriptypes.length > 0;
  const hasVenues = !_.isEmpty(allVenues) && allVenues.length > 0;
  const hasCategories = hasTopics || hasDestinations || hasTriptypes || hasVenues;

  const galleryImages = nodesUtils.getGalleryImages(assortment.gallery);
  const hasContent = assortment && (assortment.teaser || assortment.content);
  const pageParams = {
    title: coverHeader,
    cover: { ...assortment.coverImage },
  };

  return (
    <Layout
      catalogType={catalogType}
      catalogId={catalog}
      pageParams={pageParams}
      footerLogo={assortment.logo}
    >
      <HelmetDatoCms seo={assortment.seo} favicon={site.favicon} />
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 7 },
          mt: -8,
          mb: 1,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 1),
          backdropFilter: 'saturate(200%) blur(30px)',
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {isAuthorized ? (
          <Fragment>
            {hasCategories && (
              <StyledBox mb={2}>
                {hasTopics && (
                  <ProductSegments
                    segmenttype={config.catalog.topic.code}
                    segments={allTopics}
                    showHeader={true}
                    showCardHeader={false}
                    catalogType={catalogType}
                    catalogId={catalog}
                    format="large"
                  />
                )}
                {hasVenues && (
                  <ProductSegments
                    segmenttype={config.catalog.venue.code}
                    segments={allVenues}
                    showHeader={true}
                    showCardHeader={false}
                    catalogType={catalogType}
                    catalogId={catalog}
                  />
                )}
                {hasTriptypes && (
                  <ProductSegments
                    segmenttype={config.catalog.triptype.code}
                    segments={allTriptypes}
                    showHeader={true}
                    showCardHeader={false}
                    catalogType={catalogType}
                    catalogId={catalog}
                  />
                )}
                {hasDestinations && (
                  <ProductSegments
                    segmenttype={config.catalog.destination.code}
                    segments={allDestinations}
                    showHeader={true}
                    showCardHeader={false}
                    catalogType={catalogType}
                    catalogId={catalog}
                  />
                )}
              </StyledBox>
            )}
            {hasContent && (
              <StyledBox py={{ xs: 3, md: 6 }}>
                <Content
                  teaser={assortment.teaser}
                  body={assortment.content}
                  footer={assortment.footer}
                />
              </StyledBox>
            )}
            <Downloads header={'Downloads'} downloads={assortment.downloads} />
            <Gallery header={'Bildergalerie'} images={galleryImages} />
            {showNewsletter && <Newsletter />}
          </Fragment>
        ) : (
          <Locked showCancel={false} onLoginClick={handleLockedLoginClick} />
        )}
      </Card>
    </Layout>
  );
}
