import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { graphql, useStaticQuery } from 'gatsby';
import { getSrc } from 'gatsby-plugin-image';

// appsolut base components
import DefaultBackgroundCard from '../../components/shapes/Cards/BackgroundCards/DefaultBackgroundCard';
import * as pathUtils from '../../utils/path';
import * as nodesUtils from '../../utils/nodes';
import * as config from '../../../config/site';

const emptyImage =
  'https://res.cloudinary.com/appsolut365/image/upload/v1654611297/appsolut365/webportal/default/klein_bfyrwm.jpg';

function SegmentCard({ segmenttype, segment, showHeader, catalogType }) {
  // static query
  const data = useStaticQuery(graphql`
    query {
      catalogs: allDatoCmsCatalog {
        edges {
          node {
            id
            title
            originalId
          }
        }
      }
      siteSetting: datoCmsSiteSetting {
        catalog {
          id
          title
          originalId
        }
        catalogAlt {
          id
          title
          originalId
        }
      }
    }
  `);
  const { catalogs, siteSetting } = data;
  const allCatalogs = catalogs.edges;

  // parameter handling
  const node = segment && segment.node ? segment.node : {};
  const { title, alias, image } = node;

  const segmentLabel = showHeader ? config.catalog[segmenttype].label : undefined;
  const segmentTitle = alias ? alias : title;
  const pageCode = title ? title : '';
  const catalogSettings = nodesUtils.getSiteCatalogs(siteSetting, allCatalogs);
  const pageBasePath = config.catalog[segmenttype].slug;
  const pagePath = pathUtils.createPagePathOfMultiCatalog(
    catalogType,
    pageBasePath,
    pageCode,
    catalogSettings
  );
  const hasImage = !_.isEmpty(image);
  const imageSrc = hasImage ? getSrc(image.gatsbyImageData) : emptyImage;

  const actionProps = {
    type: 'internal',
    route: pagePath,
    color: 'dark',
    label: 'Wählen',
  };
  return (
    <DefaultBackgroundCard
      image={imageSrc}
      label={segmentLabel}
      title={segmentTitle}
      action={actionProps}
    />
  );
}

SegmentCard.defaultProps = {
  showHeader: true,
};

SegmentCard.propTypes = {
  segmenttype: PropTypes.string.isRequired,
  segment: PropTypes.instanceOf(Object).isRequired,
  showHeader: PropTypes.bool,
  catalogType: PropTypes.string.isRequired,
};

export default SegmentCard;

export const query = graphql`
  fragment TopicCard on DatoCmsTopic {
    id
    originalId
    title
    alias
    teaser
    noCatalog
  }

  fragment TopicWithImageCard on DatoCmsTopic {
    ...TopicCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }

  fragment DestinationCard on DatoCmsDestination {
    id
    originalId
    title
    alias
    teaser
  }

  fragment DestinationWithImageCard on DatoCmsDestination {
    ...DestinationCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }

  fragment TriptypeCard on DatoCmsTriptype {
    id
    originalId
    title
    alias
    teaser
  }

  fragment TriptypeWithImageCard on DatoCmsTriptype {
    ...TriptypeCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }

  fragment VenueCard on DatoCmsVenue {
    id
    originalId
    title
    alias
    teaser
    contact
    street
    street2
    country
    zipCode
    ort
    arrival
  }

  fragment VenueWithImageCard on DatoCmsVenue {
    ...VenueCard
    image {
      gatsbyImageData(width: 450)
      alt
      basename
    }
  }
`;
