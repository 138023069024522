import React from 'react';
import PropTypes from 'prop-types';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { Link } from 'gatsby-theme-material-ui';

// appsolut base components
import StyledBox from '../../components/controls/StyledBox';
import StyledTypography from '../../components/controls/StyledTypography';
import StyledBadge from '../../components/controls/StyledBadge';
import StyledButton from '../../components/controls/StyledButton';

function Locked({ showCancel, onCancelClick, ...rest }) {
  const docLink =
    'https://www.datocms-assets.com/16261/1659966085-reisen_reservierungsbogen_082022.pdf';

  return (
    <StyledBox component="section" py={6} {...rest}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={8}
          flexDirection="column"
          alignItems="center"
          mx="auto"
          textAlign="center"
          mb={6}
        >
          <StyledBadge
            badgeContent="Geschützter Bereich"
            variant="contained"
            color="info"
            container
            sx={{ mb: 3 }}
          />
          <StyledTypography variant="h2" mb={1}>
            Mitarbeiterbereich
          </StyledTypography>
          <StyledTypography variant="body" color="text" mb={1}>
            Aus technischen Gründen ist die Anmeldung für den Login-Bereich derzeit leider nicht
            möglich. Unsere Urlaubsangebote können sie weiterhin uneingeschränkt einsehen.
          </StyledTypography>
          <StyledTypography variant="body" color="text" mb={3}>
            Buchungen von Urlaubsreisen können Sie über unser Büro in Wien vornehmen. Dazu
            übermitteln Sie bitte einen ausgefertigten{' '}
            <StyledTypography
              component={Link}
              to={docLink}
              href={docLink}
              target="_blank"
              rel="noopener noreferrer"
              variant="body"
              color="info"
            >
              Reservierungsbogen
            </StyledTypography>{' '}
            an fair.reisen@post.at. Wir arbeiten an der Lösung des Problemes und ersuchen um ihr
            Verständnis.
          </StyledTypography>
          <Stack direction="row" spacing={2}>
            {showCancel && (
              <StyledButton onClick={onCancelClick} variant="text" size="medium" color="info">
                Zurück
              </StyledButton>
            )}
          </Stack>
        </Grid>
      </Container>
    </StyledBox>
  );
}
Locked.defaultProps = {
  showCancel: false,
};

Locked.propTypes = {
  showCancel: PropTypes.bool,
  onCancelClick: PropTypes.func,
};

export default Locked;
